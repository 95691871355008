import { GlobeIcon } from "lucide-react";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "~/components/ui/select";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import useLocale from "~/hooks/use-locale";
import { cn } from "~/lib/style";

type Props = {
	className?: string;
	showIcon?: boolean;
};

export const SelectLanguage = ({ className, showIcon = true }: Props) => {
	const { locale, setLocale } = useLocale();
	const { i18n } = useTranslation();

	const { mutate } = useMutation({
		mutationKey: ["changeLanguage"],
		mutationFn: async (language: string) => {
			const form = new FormData();
			form.append("locale", language);
			await fetch("/action/locale", {
				method: "POST",
				body: form,
			});
			return language;
		},
		onMutate: (lang) => {
			i18n.changeLanguage(lang);
			setLocale(lang);
		},
	});

	return (
		<div className="flex w-full items-center gap-4">
			{showIcon && <GlobeIcon className="h-6 w-6" />}
			<Select defaultValue={locale} onValueChange={mutate}>
				<SelectTrigger
					className={cn("w-full border-none bg-muted", className)}
				>
					<SelectValue />
				</SelectTrigger>
				<SelectContent>
					<SelectItem value="en">English (United States)</SelectItem>
					<SelectItem value="no">Norsk (Bokmål)</SelectItem>
				</SelectContent>
			</Select>
		</div>
	);
};

export default SelectLanguage;
